// react
import { useContext, useEffect } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner, ContextDocsUser } from '../../../../App.context';

// layouts
import { Authenticated } from '../../../layouts/Authenticated/Authenticated';

// ui
import { Transactions } from '../../../ui/Transactions/Transactions';

// mui
import { Grid } from '@mui/material';

export const PageBeneficiary = () => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { docsUser, isLoadingDocsUser } = useContext(ContextDocsUser);

	// set onboard banner
	useEffect(() => {
		if (!isLoadingDocsUser && !docsUser.length) {
			setBanner({
				actions: [
					{
						children: t('setupAccount'),
						color: 'primary',
						component: RouterLink,
						to: '/profile',
						variant: 'contained',
					},
				],
				message: t('alert.uploadIdentifications'),
			});
		}

		return () => {
			setBanner(null);
		};
	}, [docsUser, isLoadingDocsUser, setBanner, t]);

	return (
		<Authenticated>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Transactions role="BENEFICIARY" />
				</Grid>
			</Grid>
		</Authenticated>
	);
};
